<template>
  <AView
    :value="value"
    :active="deliverable.id === activeDeliverableId"
    :name="deliverable.name"
    ref="deliverable-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent ma-0 pa-0 elevation-0">
        <v-card-text>
          <DeliverableFilesEditorForm
            ref="form"
            v-model="deliverable"
            :uploadURL="getUploadUrl()"
            :uploadHeaders="uploadHeaders"
            @onAfterUpload="onAfterUpload"
            @onFileDelete="onFileDelete"
          ></DeliverableFilesEditorForm>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
        
        
    <script>
import { mapGetters, mapState } from "vuex";
import { DeliverablesAPIInstance } from "../../../../../../../components/api";
import DeliverableFilesEditorForm from "../../../../../../../components/wad/organisms/deliverables/forms/editor/DeliverableFilesEditorForm.vue";
//   import FormSection from "../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";

// Deliverable
/* eslint-disable no-unused-vars */

/* eslint-enable no-unused-vars */

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    DeliverableFilesEditorForm,
  },
  data() {
    return {
      deliverable: {
        searchableKeyword: {},
      },
      uploadHeaders: DeliverablesAPIInstance.uploadHeaders,
    };
  },
  computed: {
    ...mapState("DeliverableStore", [
      "activeDeliverableId",
      "displayedDeliverables",
    ]),
    ...mapGetters("DeliverableStore", ["deliverableById"]),
  },
  async created() {
    await this.$store.dispatch("DeliverableStore/GetDeliverable", {
      id: this.value.relation.params.deliverableId,
    });

    this.deliverable = this.deliverableById(
      this.value.relation.params.deliverableId
    );

    console.log("deliverable???: ", this.deliverable);
  },
  methods: {
    onAfterUpload() {
      this.$store.dispatch("DeliverableStore/GetDeliverable", {
        id: this.value.relation.params.deliverableId,
        refresh: true,
      });
    },
    onFileDelete(type, file, pr1, pr2) {
        console.log('type: ', type)
        console.log('file: ', file)
        console.log('pr1: ', pr1)
        console.log('pr2: ', pr2)

      this.$store.dispatch("DeliverableStore/DeleteDeliverableAttachment", {
        deliverable: this.deliverable,
        file,
        type,
      });
    },
    getUploadUrl() {
      return DeliverablesAPIInstance.getUploadUrl(this.deliverable);
    },
    onHover() {
      this.$store.commit(
        "DeliverableStore/setActiveId",
        this.value.relation.params.deliverableId
      );
    },
  },

  watch: {
    displayedDeliverables: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.deliverable = Object.assign(
            this.deliverable,
            this.deliverableById(this.value.relation.params.deliverableId)
          );
        }
      },
      deep: true,
    },
  },
};
</script>